import React, { Fragment } from "react";
import Button from "@mui/material/Button";

import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow
} from "@mui/material";

const PurchasesTable = ({ purchases = [] }) => {
  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table>
        <TableHead style={{ fontWeight: "700 !important" }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 800 }}>Image</TableCell>
            <TableCell sx={{ fontWeight: 800 }}>License</TableCell>
            <TableCell sx={{ fontWeight: 800 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchases.map((transaction, i) => {
            console.log(transaction);

            let date = new Date(transaction.timestamp);
            let dateString = `${date.getMonth() +
              1}/${date.getDate()}/${date.getFullYear()}`;

            return (
              <TableRow key={i} sx={{ minWidth: "100%" }}>
                <TableCell>
                  <img
                    alt={transaction.shop}
                    src={transaction.download_url}
                    width="150px"
                  />
                </TableCell>
                <TableCell sx={{ width: "100%", fontWeight: 800 }}>
                  {dateString}
                  <br />@{transaction.shop}
                  <br />
                  {transaction.license}
                </TableCell>
                <TableCell>
                  <Button
                    component={"a"}
                    href={transaction.download_url}
                    variant={"contained"}
                    download="true"
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default PurchasesTable;
