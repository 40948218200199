import React, { Fragment, useEffect, useState } from "react";

import Main from "layouts/Main";
import Container from "components/Container";
import { getMediaById, variantsForMedia, getShop, checkoutLinkForMediaVariant } from "firebase";
import { Grid, Typography } from "@mui/material";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import Confirmation from "./components/Confirmation";
import VariantList from "./components/VariantList";
import AuthModal from "components/AuthModal";

const PortfolioDetail = ({
  match: {
    params: { storename, media_id, transaction_id = null, status = null }
  }
}) => {
  // const theme = useTheme();
  //const storename = props.match.params.storename;
  //const media_id = props.match.params.media_id;

  //media should be sorted...probably better to do it server side....
  let [media, setMedia] = useState(null);
  const [shop, setShop] = useState(null);

  const auth = getAuth();
  const [user, setUser] = useState(undefined);
  const [variants, setVariants] = useState([]);
  const [displaySignIn, setDisplaySignIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        setUser(currentUser);
        setDisplaySignIn(false);
      } else {
        setUser(null);
        //redirect to sign-up
        // window.location.href = "/signin-cover"
      }
    });
  }, [auth]);

  useEffect(() => {
    getShop(storename).then(shop => {
      setShop(shop);
    });

    variantsForMedia(media_id).then(variants => {
      setVariants(variants);
    });

    getMediaById(media_id)
      .then(media => {
        setMedia(media || {});
      })
      .catch(error => {
        console.error("Unable to load shop data: " + storename);
        console.log(error);
      });
  }, [storename, media_id]);

  const onBuy = variantName => {
    if (!user) {
      setDisplaySignIn(true);
      return false;
    }

    checkoutLinkForMediaVariant(media_id, variantName, storename)
      .then(checkoutUrl => {
        window.location.replace(checkoutUrl);
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  let formComponent = <Fragment />;
  if (transaction_id && status === "success") {
    formComponent = (
      <Confirmation
        storename={storename}
        media={media}
        transactionId={transaction_id}
        status={status}
      />
    );
  } else if (media === null || shop === null) {
    formComponent = <Fragment></Fragment>;
  } else {
    formComponent = (
      <Fragment>
        <VariantList variants={variants} buyAction={onBuy} />
      </Fragment>
    );
  }

  if (media === undefined || media === null) {
    return <Main></Main>;
  }

  return (
    <Main>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant={"subtitle2"}>@{storename}</Typography>
            <Typography variant={"h6"} fontWeight={700}>
              {media.caption}
            </Typography>
          </Grid>
          <Grid container xs={12} md={12} spacing={4} pt={4} paddingX={4}>
            {formComponent}
          </Grid>
        </Grid>
      </Container>

      <AuthModal
        open={displaySignIn}
        mode="signin"
        onClose={() => {
          if (user) {
            setDisplaySignIn(false);
          }
        }}
      />
    </Main>
  );
};

export default PortfolioDetail;
