import { useEffect, useState } from "react";

import { getMediaById, updateMedia } from "firebase";
import Page from "../components/Page";
import Main from "layouts/Main";
import { Box } from "@mui/system";
import CheckCircle from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Card,
  CardHeader,
  IconButton,
  InputAdornment,
  Switch,
  TextField
} from "@mui/material"; //"@material-ui/core";
import { VariantTable } from "./components/VariantTable";

const ManageMedia = ({
  match: {
    params: { storename, mediaId }
  }
}) => {
  let [media, setMedia] = useState([]);
  const [newCaption, setNewCaption] = useState(media.caption);
  const [enabled, setEnabled] = useState(media.enabled);
  const [isSaving, setIsSaving] = useState(true);

  useEffect(() => {
    const onSave = event => {
      updateMedia(mediaId, newCaption, enabled);
    };

    setIsSaving(true);
    const timeOutId = setTimeout(() => {
      onSave(null);
      setIsSaving(false);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [newCaption, enabled, mediaId]);

  useEffect(() => {
    getMediaById(mediaId).then(async media => {
      setMedia(media);
    });
  }, [storename, mediaId]);

  return (
    <Main>
      <Page>
        <Box>
          <Card>
            <CardHeader
              avatar={
                <div>
                  <img
                    sx={{ bgcolor: "red[500]" }}
                    aria-label={storename}
                    src={media.urls?.small}
                    width={100}
                  />
                </div>
              }
              action={
                <IconButton
                  aria-label="settings"
                  onClick={() => {}}
                ></IconButton>
              }
              title={
                <div>
                  <TextField
                    key={media.caption}
                    defaultValue={media.caption}
                    fullWidth
                    onChange={event => {
                      setNewCaption(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {isSaving ? (
                            <SyncIcon color="secondary" />
                          ) : (
                            <CheckCircle sx={{ color: "green" }} />
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                  <Switch
                    defaultChecked={media.enabled ?? true}
                    onChange={event => {
                      setEnabled(event.target.checked);
                    }}
                  />{" "}
                  <b>Active</b>
                  {/* <Switch/> <Chip
                    style={{  }}
                    size={"small"}
                    label={(media.enabled === undefined || media.enabled === true) ? "active" : "inactive"}
                    color={(media.enabled === undefined || media.enabled === true) ? "primary" : undefined}
                  /> */}
                </div>
              }
            />
          </Card>
        </Box>
        <br />

        <VariantTable mediaId={media.id} media={media} />
      </Page>
    </Main>
  );
};

export default ManageMedia;
