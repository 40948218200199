import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import { getAuth, onAuthStateChanged } from "@firebase/auth";
import AuthModal from "components/AuthModal";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [displaySignIn, setDisplaySignIn] = useState(false);
  
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
  }, [auth]);

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="ArtCart"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={
              mode === 'light'
                  ? '/instastock.svg'
                  : '/instastock.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
       
        {user !== null ? (
          <Fragment>
            <Box marginTop={1}>
              <Button
                size={"large"}
                variant="text"
                color="info"
                fullWidth
                component="a"
                href="/account/downloads"
              >
                My Purchases
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                size={"large"}
                variant="text"
                color="info"
                fullWidth
                component="a"
                href="/account/galleries"
              >
                My Galleries
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                size={"large"}
                variant="contained"
                color="error"
                fullWidth
                component="a"
                href="/account-signout"
              >
                Log Out
              </Button>
            </Box>
          </Fragment>
        ) : (
          <Box marginTop={1}>
            <Button
              size={"large"}
              variant="contained"
              color="primary"
              fullWidth
              onClick={(event) => {setDisplaySignIn(true)}}
            >
              Log In
            </Button>
          </Box>
        )}
      </Box>

      <AuthModal
        open={displaySignIn}
        mode="signin"
        onClose={() => {
          if (user) {
            setDisplaySignIn(false);
          }
        }}
      />

    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
