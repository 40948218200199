import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { getInstagramAccounts, getShop, setShopEnabled } from "firebase";
import Loader from "components/Loader";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const GalleryTable = ({ user = null }) => {

  const [instagramAccounts, setInstagramAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const updateShopStatus = (shop, enabled) => {
    setShopEnabled(shop, enabled).then(result => {
      console.log("Shop status updated:" + result);
    });
  };

  useEffect(() => {

    setLoading(true)
    getInstagramAccounts()
      .then(accounts => {
        setInstagramAccounts(accounts);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [user]);

  return (
    <Fragment>
    {loading? (
      <Box textAlign={"center"} pt={5} pb={5}>
        <Loader loading={loading}/>
      </Box>
    ) : (
    <TableContainer component={Paper}>
      <Table>
      <TableHead style={{ fontWeight: "700 !important" }}>
        <TableRow>
          <TableCell sx={{ fontWeight: 800 }}>Enabled</TableCell>
          <TableCell sx={{ fontWeight: 800 }}>Account</TableCell>
          <TableCell sx={{ fontWeight: 800, whiteSpace: "nowrap" }}>
            Default Price
          </TableCell>
          <TableCell sx={{ fontWeight: 800 }}>URL</TableCell>
          <TableCell sx={{ fontWeight: 800 }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {instagramAccounts.map((account, i) => {
          const username = account.username;
          let enabled = account.enabled;

          return (
            <TableRow
              key={i}
              className={!enabled ? "disabled" : ""}
              sx={{ minWidth: "100%" }}
            >
              <TableCell>
                <Switch
                  defaultChecked={enabled}
                  onChange={event => {
                    updateShopStatus(username, event.target.checked);
                  }}
                />
              </TableCell>
              <TableCell
                sx={{ width: "100%", fontWeight: 800 }}
              >{`@${username}`}</TableCell>
              <TableCell>${account.default_price ?? 99}</TableCell>
              <TableCell>
                <Box component="a" href={`/${username}`}>
                  https://artcart.io/{username}
                </Box>
              </TableCell>
              <TableCell>
                <Button
                  href={`/account/gallery/${username}`}
                  variant={"contained"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Manage Media
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      </Table>
    </TableContainer>
    )}
    </Fragment>
  );
};

export default GalleryTable;
