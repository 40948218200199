import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { Onboarding } from "./components/Onboarding";
import { GalleryTable } from "./components";
import { isLinkedToFacebook, profileForUser } from "firebase";
import { Main } from "layouts";
import Page from "../components/Page"

const Galleries = () => {
  const [connectedToFacebook, setConnectedToFacebook] = useState(false);
  const [connectedToStripe, setConnectedToStripe] = useState(false);
  const [userOnboarded, setUserOnboarded] = useState(false);

  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(currentUser);
        console.log(currentUser);

        profileForUser(currentUser.uid).then(profile => {
          setConnectedToFacebook(isLinkedToFacebook());

          if (profile.stripe_charges_enabled === true) {
            setConnectedToStripe(true);
          }

          setUserOnboarded(connectedToFacebook && connectedToStripe);
          setUser(profile);
        });
      } else {
        setUser(null);
        setConnectedToFacebook(false);
        setConnectedToStripe(false);
      }
    });
  }, [auth, connectedToFacebook, connectedToStripe]);

  if (user === null || user === undefined) {
    return <Typography>Loading</Typography>;
  }

  return (
    <Main>
      <Page>
        {userOnboarded === false ? (
          <Onboarding user={user} />
        ) : (
          <GalleryTable />
        )}
      </Page>
    </Main>
  );
};

export default Galleries;
