import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Paper
} from "@mui/material"; //"@material-ui/core";
import { MediaModal } from "../MediaModal";
import EditIcon from "@mui/icons-material/Edit";
import { urlForFilePath, variantsForMedia } from "firebase";

const VariantTable = ({ media = null }) => {
  let [variants, setVariants] = useState([]);
  let [selectedVariant, setSelectedVariant] = useState({});
  let [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  useEffect(() => {
    if (!media || !media.id) {
      return;
    }

    variantsForMedia(media.id).then(async results => {
      for (let index in results) {
        const [variantName, variant] = results[index];

        console.log(variantName);
        const filename = variant.filename;
        const url = await urlForFilePath(filename);
        variant.imageUrl = url;
      }

      setVariants(results);
      setSelectedVariant(results[0][1]);
    });
  }, [media]);

  const handleMediaDialogClose = event => {
    setMediaDialogOpen(false);
    if (event && selectedVariant) {
      let [, originalRow] = variants.find(item => {
        let [, itemData] = item;
        return itemData.id === selectedVariant.id;
      });
      originalRow.caption = selectedVariant.caption;
      originalRow.enabled = selectedVariant.enabled;
    }
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <Fragment>
      <Box
        fullWidth
        sx={{
          textAlign: "right"
        }}
      >
        <Button
          onClick={() => {
            setSelectedVariant(undefined);
            setMediaDialogOpen(true);
          }}
        >
          Add New Variant
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">Variant</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variants.map(([rowId, row]) => {
              console.log(row);
              return (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    width="120px"
                  >
                    <img
                      alt={row.name}
                      src={row.imageUrl}
                      height={"100px"}
                    />
                  </TableCell>
                  <TableCell align="left" width="100%">
                    <div>
                      <Typography style={{ fontWeight: 900 }}>
                        {row.name}
                      </Typography>
                      <Typography variant={"caption"}>
                        Dimensions: {row.metadata.width}x{row.metadata.height} •
                        File Size: {formatBytes(row.metadata.size)}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        fontWeight: !row.price ? 400 : 900,
                        fontStyle: !row.price ? "italic" : "inherit",
                        color: !row.price ? "lightgray" : "inherit"
                      }}
                    >
                      {row.price ? `$${row.price}` : "Default"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      label={
                        row.enabled === undefined || row.enabled === true
                          ? "ACTIVE"
                          : "INACTIVE"
                      }
                      color={
                        row.enabled === undefined || row.enabled === true
                          ? "primary"
                          : undefined
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        console.log(row);
                        setSelectedVariant(Object.assign({}, row));
                        setMediaDialogOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <MediaModal
        key={selectedVariant?.name ?? "New Variant"}
        isOpen={mediaDialogOpen}
        onClose={handleMediaDialogClose}
        media={media}
        variant={selectedVariant}
      />
    </Fragment>
  );
};

export default VariantTable;
