import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const Main = (props) => {
  const theme = useTheme();
  const media = props.media;
  const storename = props.storename;

  console.log(media);

  return (
    <Box>
      <Grid container spacing={{xs: 0.5, md: 4}}>
        {Object.values(media).map(([key, item], i) => {

          return (
            <Grid item xs={4} sm={4} md={4} key={i}>
              <Box
                component={"a"}
                href={`/${storename}/${key}`}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={{xs: 0, md: 3}}
                >
                  <CardMedia
                    image={`${item.urls?.small}`}
                    title={item.caption || "Untitled"}
                    sx={{
                      height: { xs: "33vw", md: 400 },
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  />
                  <Box
                    component={CardContent}
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    <Typography variant={"h6"} fontWeight={700} gutterBottom>
                      {item.caption || "Untitled"}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Main;
