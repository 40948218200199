import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Container from "components/Container";
import { getTransactionbyId } from "firebase";

const Confirmation = props => {
  const media = props.media;
  const transactionId = props.transactionId;
  const status = props.status;

  const [transaction, setTransaction] = useState(undefined);
  useEffect(() => {
    getTransactionbyId(transactionId).then(transaction => {
      setTransaction(transaction);
    });
  }, [transactionId, status]);

  const onDownload = () => {
    const link = document.createElement("a");
    //link.download = transaction.download_url;
    link.href = transaction.download_url;
    link.setAttribute("download", "true");
    link.click();
  };

  if (transaction === undefined || status !== "success") {
    return <></>;
  }
  return (
    <Container>
      <Grid container spacing={4} xs={12} md={12}>
        <Grid item xs={6} md={6}>
          <img
            alt={media.caption || "Untitled"}
            src={transaction.download_url}
            width={"100%"}
          />
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="flex-start"
        >
          <Box>
            <Typography variant={"h6"} fontWeight={700} gutterBottom>
              Thank you for your purchase!
            </Typography>
          </Box>

          <Box>
            <Button
              size="small"
              variant={"contained"}
              onClick={() => onDownload()}
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Confirmation;
