import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Loader from "components/Loader";

import { profileForUser, getPurchasedMedia } from "firebase";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import PurchasesTable from "./components/PurchasesTable/PurchasesTable";
import { Main } from "layouts";
import { Page } from "../components";

const Purchases = () => {
  const [user, setUser] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(currentUser);
        console.log(currentUser);

        profileForUser(currentUser.uid).then(profile => {
          setUser(profile);
          getPurchasedMedia().then(purchases => {
            setPurchases(purchases);
            setLoading(false);
          });
        });
      } else {
        setUser(null);
      }
    });
  }, [auth]);

  return (
    <Main>
      <Page>
        {loading ? (
          <Box textAlign={"center"} pt={5} pb={5}>
            <Loader loading={loading} />
          </Box>
        ) : (
          <PurchasesTable purchases={purchases} />
        )}
      </Page>
    </Main>
  );
};

export default Purchases;
