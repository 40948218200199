import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy
} from "firebase/firestore";

import { getStorage, getDownloadURL, ref } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  linkWithPopup,
  unlink,
  signInWithEmailAndPassword,
  updateProfile
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDh8CtDlxfq7VPUb8ysnTlGJYHxlGUOfVE",
  authDomain: "instastock-d1793.firebaseapp.com",
  projectId: "instastock",
  storageBucket: "instastock.appspot.com",
  messagingSenderId: "570341831091",
  appId: "1:570341831091:web:e577a577f6fe971a7cdbc6",
  measurementId: "G-4S4EM4ZKEW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

const auth = getAuth();

const getShopReference = storename => {
  const docRef = doc(firestore, "shops", storename);
  return docRef;
};

const getShop = async storename => {
  const docRef = getShopReference(storename);
  const snapshot = await getDoc(docRef);
  const doc = snapshot.data();
  return doc;
};

const refreshShopMedia = async storename => {
  const refreshMedia = httpsCallable(functions, "refreshShopMedia");
  const response = await refreshMedia({
    shop: storename
  });
  return response;
};

const setShopEnabled = async (storename, isEnabled) => {
  const updateShopStatus = httpsCallable(functions, "updateShopStatus");
  const response = await updateShopStatus({
    shop: storename,
    enabled: isEnabled
  });
  return response;
};

const updateShop = async (storename, enabled, defaulPrice) => {
  const docRef = doc(firestore, "shops", storename);
  await setDoc(
    docRef,
    {
      default_price: defaulPrice,
      enabled: enabled
    },
    { merge: true }
  );
};

const getMediaById = async media_id => {
  let docRef = undefined;
  docRef = doc(firestore, "media", media_id);
  const snapshot = await getDoc(docRef);

  return snapshot.data();
};

const updateMedia = async (mediaId, caption, enabled) => {
  let docRef = doc(firestore, "media", mediaId);

  const payload = {};

  if (enabled !== undefined) {
    payload.enabled = enabled;
  }

  if (caption) {
    payload.caption = caption;
  }

  await updateDoc(docRef, payload);
};

const urlForFilePath = async filepath => {
  const fileRef = ref(storage, filepath);
  let urlString = "no-image-found";

  try {
    urlString = await getDownloadURL(fileRef);
  } catch (error) {
    urlString = "no-image-found";
  }

  return urlString;
};

const variantsForMedia = async mediaId => {
  const mediaRef = collection(firestore, "media", mediaId, "variants");
  const snapshots = await getDocs(mediaRef);
  const docs = [];
  snapshots.forEach(snapshot => {
    const data = snapshot.data();
    docs.push([snapshot.id, data]);
  });
  return docs;
};

const queryMediaForStore = async storename => {
  const mediaRef = collection(firestore, "media");
  const q = query(
    mediaRef,
    where("instagram_username", "==", storename),
    orderBy("timestamp", "desc")
  );

  const snapshots = await getDocs(q);
  const docs = [];
  snapshots.forEach(doc => {
    docs.push([doc.id, doc.data()]);
  });
  return docs;
};

const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const user = userCredential.user;
      return user;
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(`Failed to Sign In (${errorCode}): ${errorMessage}`);

      return error;
    });
};
const profileForUser = userId => {
  const docRef = doc(firestore, "users", userId);
  return getDoc(docRef)
    .then(snapshot => {
      console.log(snapshot);
      let data = snapshot.data();
      return data;
    })
    .catch(error => {
      return error;
    });
};

const isLinkedToFacebook = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (currentUser === null) {
    return false;
  }

  const providerData = currentUser.providerData;
  const providers = providerData.filter(item => {
    if (item.providerId === "facebook.com") {
      return true;
    }

    return false;
  });

  return providers.length > 0;
};

const unlinkFacebook = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser === null) {
    return;
  }

  const provider = new FacebookAuthProvider();
  return unlink(currentUser, provider.providerId);
};

const linkToFacebook = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser === null) {
    return;
  }

  const provider = new FacebookAuthProvider();
  provider.addScope("public_profile");
  provider.addScope("instagram_basic");
  //provider.addScope("pages_read_engagement");
  provider.addScope("pages_show_list");

  return linkWithPopup(currentUser, provider)
    .then(result => {
      const credentials = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credentials.accessToken;
      return accessToken;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};
const registerUser = (email, password, firstName = "", lastName = "") => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const user = userCredential.user;
      updateProfile(user, {
        displayName: `${firstName} ${lastName}`
      });

      return user;
      // ...
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(`Failed to Regsier User (${errorCode}): ${errorMessage}`);
      return error;
      // ..
    });
};

/// BEGIN CALLABLE FUNCTIONS

/**
 * Get list of instagram accounts associated with the current user's facebook account
 * @returns array of instragram accounts associated ith current user
 */
const getInstagramAccounts = () => {
  const getInstagramPages = httpsCallable(functions, "getInstagramAccounts");
  return getInstagramPages()
    .then(result => {
      return result.data;
    })
    .catch(error => {
      console.log(error);
    });
};

/**
 * Sends fresh FB access token to server to create a long-lived token
 * @param {string} accessToken
 * @returns
 */
const getLongLivedFacebookToken = accessToken => {
  const getLongLivedToken = httpsCallable(functions, "getLongLivedToken");
  return getLongLivedToken({
    accessToken: accessToken
  });
};

/**
 * Starts process of onboarding user to Stripe
 * @returns Stripe Onboarding object
 */
const onboardToStripe = () => {
  const onboardToStripe = httpsCallable(
    functions,
    "onboardUserAccountToStripe"
  );
  return onboardToStripe().then(results => {
    return results.data;
  });
};

/**
 *
 * @param {string} mediaId
 * @param {string} variantName
 * @param {boolean} enabled
 * @param {number} price
 * @param {blob} fileData
 * @returns
 */
export const updateMediaVariant = (
  mediaId,
  variantName,
  enabled = true,
  price = undefined,
  fileData = undefined
) => {
  const updateVariantForMedia = httpsCallable(
    functions,
    "updateVariantForMedia"
  );

  return updateVariantForMedia({
    mediaId,
    variantName,
    price,
    fileData
  });
};

/**
 *
 * @param {string} mediaId
 * @param {string} variantName
 * @param {boolean} enabled
 * @param {number} price
 * @param {blob} fileData
 * @returns
 */
export const createMediaVariant = (
  mediaId,
  variantName,
  enabled = true,
  price = undefined,
  fileData = undefined
) => {
  const createVariantForMediaCallable = httpsCallable(
    functions,
    "createVariantForMedia"
  );

  return createVariantForMediaCallable({
    mediaId: mediaId,
    variantName: variantName,
    price: price,
    fileData: fileData,
    enabled: enabled
  });
};

export const getPurchasedMedia = () => {
  const getPurchasedMedia = httpsCallable(functions, "getPurchasedMedia");
  return getPurchasedMedia().then(data => {
    return data.data.purchases;
  });
};

/**
 *
 * @param {string} mediaId
 * @param {string} variantName
 * @param {string} storename
 * @returns
 */
export const checkoutLinkForMediaVariant = (
  mediaId,
  variantName,
  storename
) => {
  const chekcoutForMedia = httpsCallable(functions, "checkoutForMedia");
  return chekcoutForMedia({
    media_id: mediaId,
    variant: variantName,
    shop: storename
  }).then(result => {
    const data = result.data;
    const checkoutUrl = data.checkout_url;
    return checkoutUrl;
  });
};

/**
 *
 * @param {string} transactionId
 * @returns
 */
export const getTransactionbyId = async transactionId => {
  const callable = httpsCallable(functions, "getTransaction");
  const results = await callable({
    transactionId
  }).catch(error => {
    console.log(error);
  });

  console.log(results);
  const data = results.data;
  const transaction = data.transaction;
  return transaction;
};

// LOCAL TESTING CONFIGURATIONS
if (process.env.REACT_APP_LOCAL_FUNCTIONS === "true") {
  console.log("USING LOCAL FUNCTIONS: " + process.env.REACT_APP_LOCAL_FUNCTIONS);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
//connectFirestoreEmulator(firestore, "localhost", 8080);

//const auth = getAuth();
//connectAuthEmulator(auth, "http://localhost:9099");

export {
  analytics,
  functions,
  firestore,
  signIn,
  registerUser,
  getShopReference,
  getShop,
  refreshShopMedia,
  setShopEnabled,
  getInstagramAccounts,
  onboardToStripe,
  linkToFacebook,
  unlinkFacebook,
  isLinkedToFacebook,
  getLongLivedFacebookToken,
  updateShop,
  getMediaById,
  updateMedia,
  profileForUser,
  queryMediaForStore,
  variantsForMedia,
  urlForFilePath
};
