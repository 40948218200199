import {
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Box,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { tableCellClasses } from "@mui/material/TableCell";
import { createMediaVariant, updateMediaVariant } from "firebase";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const MediaModal = ({
  media,
  variant,
  isOpen = false,
  onClose = event => {}
}) => {
  let [isNewVariant, setIsNewVariant] = useState(variant === undefined);
  let [selectedVariant, setSelectedVariant] = useState(variant ?? {});

  if (selectedVariant === undefined) {
    setIsNewVariant(true);
    setSelectedVariant({});
  }

  useEffect(() => {
    if (isOpen === false || !media) {
      return;
    }
  }, [media, isOpen]);

  const handleSave = async (event = null) => {
    // update all variants
    // TODO:  Variants Sould be Saved Individually

    const saveVariant = (variantName, variant, fileBuffer = null) => {
      let updateVariantForMedia = updateMediaVariant;

      if (isNewVariant) {
        updateVariantForMedia = createMediaVariant;
      }

      return updateVariantForMedia(
        media.id,
        variantName,
        variant.enabled,
        variant.price,
        fileBuffer
      )
        .then(result => {
          // Read result of the Cloud Function.
          /** @type {any} */
          console.log("Response from Update MediaVariant:");
          console.log(result);
          return true;
        })
        .catch(error => {
          console.log(error);
        });
    };

    if (selectedVariant.variantFile) {
      //upload to storage and update file path

      const reader = new FileReader();
      reader.onload = function(e) {
        const fileBuffer = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        console.log(fileBuffer);
        console.log(selectedVariant);
        saveVariant(selectedVariant.name, selectedVariant, fileBuffer).then(
          () => {
            onClose(event);
          }
        );
      };

      reader.readAsDataURL(selectedVariant.variantFile);
    } else {
      saveVariant(selectedVariant.name, selectedVariant).then(() => {
        onClose(event);
      });
    }
  };

  const Dropzone = props => {

    const variant = props.variant;
    const [files, setFiles] = useState([variant.imageUrl]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: "image/*",
      maxFiles: 1,
      onDrop: acceptedFiles => {

        if(acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          setFiles([URL.createObjectURL(file)]);
          variant.variantFile = acceptedFiles[0];
        }
      }
    });

    return (
      <section className="container">
        <div {...getRootProps({ className: "dropzone", height: "100%" })}>
          <input {...getInputProps()} />
          <Box
          sx={{
            backgroundImage: `url(${files[0]})`,
            backgroundColor: "gray",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            width: "100%",
            paddingTop: "100%"
          }}
        >
          {isDragActive || !files[0] ? (
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.50)",
                marginTop: "-100%",
                height: "250px"
              }}
              display="flex"
              flexDirection="column"
              textAlign="center"
              justifyContent="center"
            >
              <div>
              <AddPhotoAlternateIcon fontSize="large" color="primary"/>
              </div>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box>
          <Typography variant={"caption"}>
            Dimensions: {variant.metadata?.width}x{variant.metadata?.height}
          </Typography>
        </Box>
        </div>
        
      </section>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {isNewVariant ? "Create" : "Update"} Media Variant
      </DialogTitle>
      <DialogContent width={"500px"}>
        <Box>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Dropzone variant={selectedVariant}></Dropzone>
            </Grid>

            <Grid item md={6}>
              <Table
                size="small"
                padding="none"
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                  }
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Enabled{" "}
                      <Switch
                        defaultChecked={selectedVariant.enabled}
                        onChange={event => {
                          selectedVariant.enabled = event.target.checked;
                          console.log(selectedVariant.enabled);
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <TextField
                        margin="normal"
                        id="name"
                        label="Variant Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        key={selectedVariant.name || "no-value"}
                        defaultValue={selectedVariant["name"] || ""}
                        placeholder={""}
                        required
                        disabled={isNewVariant === false}
                        onChange={event => {
                          selectedVariant.name = event.target.value;
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        margin="normal"
                        id="price"
                        label="Price"
                        type="text"
                        fullWidth
                        variant="standard"
                        key={selectedVariant.price || "no-value"}
                        defaultValue={selectedVariant["price"] || ""}
                        placeholder={"Default"}
                        onChange={event => {
                          selectedVariant.price = event.target.value;
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(null);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={event => {
            handleSave(event);
          }}
          variant="contained"
          color={"primary"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaModal;
