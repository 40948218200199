import { useTheme, Box } from "@mui/material";
import { Fragment } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import GridLoader from "react-spinners/GridLoader";

const Loader = ({ loading = true, isGrid = false }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <Fragment>
      {isGrid === false ? (
        <ScaleLoader color={color} loading={loading} size={50} />
      ) : (
        <Box width="70px" m={"auto"}>
          <GridLoader color={color} loading={loading}/>
        </Box>
      )}
    </Fragment>
  );
};

export default Loader;
