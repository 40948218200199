import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

import {
  IndexView,
  PortfolioGrid as PortfolioGridView,
  PortfolioDetail as PortfolioDetailView,
  PasswordResetCover as PasswordResetCoverView,
  PasswordResetSimple as PasswordResetSimpleView,
  AccountPurchases as AccountPurchasesView,
  AccountGalleries as AccountGalleriesView,
  AccountManageGallery as AccountManageGalleryView,
  AccountManageMedia as AccountManageMediaView,
  AccountSecurity as AccountSecurityView,
  Dashboard as DashboardView,
  NotFound as NotFoundView,
  NotFoundCover as NotFoundCoverView
} from "./views";
import ProtectedRoute from "components/ProtectedRoute";

// Documentation pages

const Routes = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const auth = getAuth();
  
  return (
    <Switch>
      <Route exact path="/">
        {isAuthenticated ? <Redirect to="/account" /> : <IndexView />}
      </Route>
      <Route
        exact
        path="/password-reset-cover"
        render={() => <PasswordResetCoverView />}
      />
      <Route
        exact
        path="/password-reset-simple"
        render={() => <PasswordResetSimpleView />}
      />
      <ProtectedRoute
        exact
        path="/account/downloads"
        component={AccountPurchasesView}
      />
      <ProtectedRoute
        exact
        path="/account/galleries"
        component={AccountGalleriesView}
      />
      <ProtectedRoute
        match
        path="/account/gallery/:storename/:mediaId"
        component={AccountManageMediaView}
      />
      <ProtectedRoute
        match
        path="/account/gallery/:storename"
        component={AccountManageGalleryView}
      />
      <ProtectedRoute
        exact
        path="/account-security"
        component={AccountSecurityView}
      />
      <Route
        exact
        path="/account-logout"
        render={() => {
          signOut(auth).then(() => {
            window.location.href = "/";
          });
        }}
      />
      <Route exact path="/account">
        <Redirect to="/account/downloads" />
      </Route>
      <Route exact path="/dashboard" component={DashboardView} />
      <Route exact path="/not-found" render={() => <NotFoundView />} />
      <Route
        exact
        path="/not-found-cover"
        render={() => <NotFoundCoverView />}
      />
      <ProtectedRoute
        match
        path="/:storename/:media_id/:transaction_id/:status"
        component={PortfolioDetailView}
      />
      <Route
        match
        path="/:storename/:media_id"
        component={PortfolioDetailView}
      />
      <Route match path="/:storename" component={PortfolioGridView} />
      <Redirect to="/not-found-cover" />
    </Switch>
  );
};

export default Routes;
