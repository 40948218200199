import { Fragment } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Paper
} from "@mui/material"; //"@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";

const MediaTable = ({ mediaList = [] }) => {

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Variants</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mediaList.map(([rowId, row]) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <img
                    alt={row.caption}
                    src={row.urls?.small}
                    width={"100px"}
                    height={"100px"}
                  />
                </TableCell>
                <TableCell align="left">
                  <div>
                    <Typography style={{ fontWeight: 900 }}>
                      {row.caption}
                    </Typography>
                    <Typography variant={"caption"}>
                      {new Date(row.timestamp).toLocaleDateString("en-US")}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Chip
                  color={"secondary"}
                    label={row.variantCount}
                  >
                    
                  </Chip>
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={
                      row.enabled === undefined || row.enabled === true
                        ? "ACTIVE"
                        : "INACTIVE"
                    }
                    color={
                      row.enabled === undefined || row.enabled === true
                        ? "primary"
                        : undefined
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      window.location.href = window.location.href + `/${rowId}`;
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Fragment>
  );
};

export default MediaTable;
