import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Cancel";
import SigninForm from "./SignInForm";
import PropTypes from "prop-types";
import SignupForm from "./SignupForm";
import { useState } from "react";

const AuthModal = ({ mode = "signin", open = false, onClose = () => {} }) => {
  let [currentMode, setCurrentMode] = useState(mode);

  return (
    <Dialog open={open}>
      <DialogActions>
        <IconButton
          onClick={event => {
            open = false;
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        {currentMode === "signin" ? (
          <SigninForm
            onToggleMode={() => {
              setCurrentMode("signup");
            }}
            onSignIn={() => {
              console.log("Signed In");
              // open = false;
              // onClose();
            }}
          />
        ) : (
          <SignupForm
            onToggleMode={() => {
              setCurrentMode("signin");
            }}
            onSignUp={() => {
              console.log("Signed Up");
              // open = false;
              // onClose();
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AuthModal.propTypes = {
  mode: PropTypes.oneOf(["signin", "signup"]),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default AuthModal;
