import {
  useEffect,
  useState
} from "react";

import {
  getShopReference,
  queryMediaForStore,
  updateShop,
} from "firebase";
import { getDoc } from "@firebase/firestore";
import Page from "../components/Page";
import Main from "layouts/Main";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box } from "@mui/system";
import {
  Typography,
  NativeSelect,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Card,
  CardHeader,
  Avatar,
  Chip,
  IconButton,
} from "@mui/material"; //"@material-ui/core";
import { MediaTable } from "./components/MediaTable";

const ManageGallery = ({
  match: {
    params: { storename }
  }
}) => {
  let [store, setStore] = useState({});
  let [media, setMedia] = useState([]);
  let [storeDialogOpen, setStoreDialogOpen] = useState(false);
  let [selectedStoreObject, setSelectedStoreObject] = useState({});

  useEffect(() => {
    const shopRef = getShopReference(storename);
    getDoc(shopRef).then(snapshot => {
      const data = snapshot.data();
      console.log(data);
      setStore(data);
    });

    queryMediaForStore(storename).then(async mediaDocs => {
      setMedia(mediaDocs);
    });
  }, [storename]);

  const handleStoreDialogClose = event => {
    setStoreDialogOpen(false);

    if (event) {
      store.default_price = selectedStoreObject.default_price;
      store.enabled = selectedStoreObject.enabled === "true";

      updateShop(storename, store.enabled, store.default_price).then(() => {
        console.log("Shop Settings Updated");
      });
    }
  };

  return (
    <Main>
      <Page>
        <Box>
          <Card>
            <CardHeader
              avatar={
                <div>
                  <Avatar
                    sx={{ bgcolor: "red[500]" }}
                    aria-label={storename}
                    src={store.profile_picture_url}
                  />
                </div>
              }
              action={
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    setSelectedStoreObject(Object.assign({}, store));
                    setStoreDialogOpen(true);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              }
              title={
                <div>
                  <b>@{storename}</b>
                  <Chip
                    style={{ float: "right", marginTop: "4px" }}
                    size={"small"}
                    label={store.enabled === true ? "active" : "inactive"}
                    color={store.enabled === true ? "primary" : undefined}
                  />
                </div>
              }
              subheader={
                <div>
                  <Typography variant={"caption"}>
                    Default Media Price: ${store.default_price}
                  </Typography>
                </div>
              }
              caption={"Hello World"}
            />
          </Card>
        </Box>
        <br />

        <MediaTable mediaList={media} />

        <Dialog open={storeDialogOpen} onClose={handleStoreDialogClose}>
          <DialogTitle>Store Settings</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              id="default_price"
              label="Default Price"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={selectedStoreObject.default_price ?? ""}
              onChange={event => {
                selectedStoreObject.default_price = event.target.value;
              }}
            />
            <br />
            <br />
            <NativeSelect
              defaultValue={selectedStoreObject.enabled}
              variant={"filled"}
              style={{ width: "100px" }}
              inputProps={{
                name: "enabled",
                id: selectedStoreObject.id
              }}
              onChange={event => {
                selectedStoreObject.enabled = event.target.value;
              }}
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </NativeSelect>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setStoreDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleStoreDialogClose}
              variant="contained"
              color={"primary"}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    </Main>
  );
};

export default ManageGallery;
