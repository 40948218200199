import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  getLongLivedFacebookToken,
  isLinkedToFacebook,
  linkToFacebook,
  onboardToStripe,
  unlinkFacebook
} from "firebase";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Onboarding = ({ user = null, onOnboardingComplete = () => {} }) => {
  const [connectedToFacebook, setConnectedToFacebook] = useState(false);
  const connectedToStripe =
    user.stripe_charges_enabled !== undefined &&
    user.stripe_charges_enabled !== false;

  useEffect(() => {
    setConnectedToFacebook(isLinkedToFacebook());
  }, [user]);

  console.log(user);
  const connectToFacebook = async () => {
    const accessToken = await linkToFacebook();
    if (accessToken) {
      getLongLivedFacebookToken(accessToken)
        .then(result => {
          setConnectedToFacebook(true);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const disconnectFacebook = async () => {
    await unlinkFacebook();
    setConnectedToFacebook(false);
  };

  const connectToStripe = () => {
    onboardToStripe()
      .then(data => {
        const onboardingUrl = data.onboarding_url;
        window.location.replace(onboardingUrl);
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", md: "left" }}
      >
        <Typography variant="h6" fontWeight={700}>
          Setup Seller Account
        </Typography>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>

      <Accordion expanded={!connectedToFacebook}>
        <AccordionSummary
          expandIcon={

            (connectedToFacebook === true) ? 
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={event => {
                disconnectFacebook();
              }}
            >
              Disconnect
            </Button>

            :
            <></>
          }
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h6">
              1. Connect to Facebook{" "}
              <CheckCircleIcon
                style={{ display: connectedToFacebook ? "inline" : "none" }}
                color="primary"
              />
            </Typography>
            <Typography variant="caption"></Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* <ReactFacebookLogin
              appId="1319711721546510"
              autoLoad={connectedToFacebook}
              size="small"
              fields="name,email,picture"
              scope="public_profile,instagram_basic,pages_read_engagement,pages_show_list"
              onClick={() => {
                connectToFaceboook();
              }}
              callback={responseFacebook}
            /> */}

            <Button
              variant="contained"
              onClick={async event => {
                console.log("Linking to facebook!");
                connectToFacebook();
              }}
            >
              Connect To Facebook
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={connectedToStripe === false}>
        <AccordionSummary>
          <Box>
            <Typography variant="h6">
              2. Connect to Stripe{" "}
              <CheckCircleIcon
                style={{
                  display:
                    user.stripe_charges_enabled === true ? "inline" : "none"
                }}
                color="primary"
              />
            </Typography>
            <Typography variant="caption">
              Get paid when customers buy your images
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Button
              variant="contained"
              onClick={event => {
                connectToStripe();
              }}
            >
              Connect to Stripe
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Onboarding;
