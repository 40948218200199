import React, { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

const VariantList = ({ variants = [], buyAction = () => {} }) => {
  const theme = useTheme();
  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <Fragment>
      {variants.map(([variantName, variant], i) => {
        return (
          <Grid item xs={12} sm={12} md={4} key={i}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "column" }
              }}
            >
              <CardMedia
                image={`${variant.urls?.large}`}
                title={variantName || "Untitled"}
                sx={{
                  height: { xs: "33vw", md: 300 },
                  width: { xs: "33vw", md: "100%" },

                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                  backgroundSize: "contain"
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flex: "1 0 auto",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: {
                    xs: "flex-start",
                    md: "flex-start"
                  }
                }}
              >
                <Box flexGrow={{xs: 1, md: 0}}/>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  sx={{
                    alignItems: {
                      xs: "flex-start",
                      md: "flex-start"
                    }
                  }}
                >
                  <Typography variant={"button"} fontWeight={700}>
                  <span fontWeight={700}>{variantName}</span> ${variant.price}
                  </Typography>
                  <Typography variant={"caption"}>
                    Dimensions: {variant.metadata.width}x{variant.metadata.height}<br/>
                    File Size: {formatBytes(variant.metadata.size)}
                  </Typography>
                </Box>

                <Box sx={{ flexGrow: { xs: 0, md: 1 } }} />

                <Box
                  component={CardActions}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      md: "row"
                    },
                    padding: 0,
                    paddingTop: {
                      xs: "8px",
                      md: "0"
                    },
                    justifyContent: "flex-end"
                  }}
                  width={{ xs: "100%", sm: "auto" }}
                >
                  <Button
                    size="small"
                    variant={"contained"}
                    onClick={() => buyAction(variant.name)}
                    fullWidth
                  >
                    Buy Now
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default VariantList;
