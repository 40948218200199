import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  IconButton,
  Typography
} from "@mui/material"

import SettingsIcon from "@mui/icons-material/Settings";

import Container from "components/Container";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { isLinkedToFacebook, profileForUser } from "firebase";
import { Tab, Tabs } from "@mui/material";

const tabs = [
  {
    id: "downloads",
    href: "/account/downloads",
    title: "Downloads"
  },
  {
    id: "galleries",
    href: "/account/galleries",
    title: "My Galleries"
  }
  // ,
  // {
  //   id: "settings",
  //   href: "/account/settings",
  //   title: "Settings"
  // }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const Page = ({ children }) => {
  const [activeLink, setActiveLink] = useState("");
  const [user, setUser] = useState(null);
  const [isSeller, setIsSeller] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const auth = getAuth();

  useEffect(() => {
    const pathname = window && window.location ? window.location.pathname : "";
    setActiveLink(pathname);
    console.log(pathname);

    const activeIndex = tabs.findIndex((item, index) => {
      if (item.href === pathname) {
        return true;
      }

      return false;
    });

    console.log(activeIndex);
    setValue(activeIndex);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        profileForUser(currentUser.uid).then(profile => {
          const isConnectedToFacebook = isLinkedToFacebook();
          setIsSeller(
            isConnectedToFacebook && profile.stripe_charges_enabled === true
          );
          setUser(profile);
        });
      } else {
        setUser(null);
        setIsSeller(false);
      }
    });
  }, [auth]);


  if (user === null) {
    return <></>;
  }
  return (
    <Box>
      <Box bgcolor={"primary.main"} paddingY={0}>
        <Container>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: "common.white" }}
          >
            My Account&nbsp;
            <IconButton sx={{color: "common.white"}}>
            <SettingsIcon/>
            </IconButton>
          </Typography>
          <Typography variant="h6" sx={{ color: "common.white" }}>
          </Typography>

          <Box sx={{ bgcolor: "background.paper" }}>
            {/* <AppBar position="static"> */}
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {tabs.map((item, index) => {

                if(item.id === "galleries" && isSeller === false) {
                  return (
                    <Box id={index} style={{width: `${(100/tabs.length)}%`, textAlign: "center"}} pt={1} pb={1} pl={20} pr={10}>
                    <Button variant="contained" size="small" href={item.href} fullWidth>Get Started Selling</Button>
                    </Box>
                  )
                }

                return (
                  <Tab
                    key={index}
                    label={item.title}
                    {...a11yProps(index)}
                    onClick={() => {
                      window.location.href = item.href;
                    }}
                  />
                );
              })}
            </Tabs>
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node
};

export default Page;
