import React, { useEffect, useState } from "react";
import Typed from "react-typed";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";

import Container from "components/Container";
import AuthModal from "components/AuthModal";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

const images = [
  {
    group: [
      {
        cover:
          "https://storage.googleapis.com/instastock.appspot.com/media/thumbnails/17868324101586443/17868324101586443-default-200-thumb/ac8e735e-20ef-41d9-878e-ab6a7fa0b156.jpg",
        coverDark:
          "https://storage.googleapis.com/instastock.appspot.com/media/thumbnails/17868324101586443/17868324101586443-default-200-thumb/ac8e735e-20ef-41d9-878e-ab6a7fa0b156.jpg"
      },
      {
        cover:
          "https://storage.googleapis.com/instastock.appspot.com/media/thumbnails/17855592584288240/17855592584288240-default-200-thumb/b97b987a-8664-4c7a-b2c9-3d3b3137e18f.jpg",
        coverDark:
          "https://storage.googleapis.com/instastock.appspot.com/media/thumbnails/17855592584288240/17855592584288240-default-200-thumb/b97b987a-8664-4c7a-b2c9-3d3b3137e18f.jpg"
      }
    ]
  },
  {
    group: [
      {
        cover:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/79585425_3089691371256714_8205230032668589971_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=g3L6a-oid-oAX8ttrtw&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=0b02e2e54e2cb277e462c31a23779fa9&oe=617B7CC9",
        coverDark:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/79585425_3089691371256714_8205230032668589971_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=g3L6a-oid-oAX8ttrtw&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=0b02e2e54e2cb277e462c31a23779fa9&oe=617B7CC9"
      },
      {
        cover:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/67568405_400334203954372_1503686204778929754_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=3wqTj1F4eAMAX9a_BTu&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=59f8fc7433bc5d29c14186a48c79ce65&oe=617B70D3",
        coverDark:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/67568405_400334203954372_1503686204778929754_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=3wqTj1F4eAMAX9a_BTu&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=59f8fc7433bc5d29c14186a48c79ce65&oe=617B70D3"
      },
      {
        cover:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/37074973_232871124020131_3569797093528698880_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ZYIXHcgXhQ4AX8B9zx-&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=d550bf5b04583479e369186e34b0775e&oe=617C8E17",
        coverDark:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/37074973_232871124020131_3569797093528698880_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ZYIXHcgXhQ4AX8B9zx-&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=d550bf5b04583479e369186e34b0775e&oe=617C8E17"
      }
    ]
  },
  {
    group: [
      {
        cover:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/42833393_2012094665533658_7578947685992540629_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=XyDroMo1YGAAX9_JS0f&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=14b91e4266be91ff72ca8b83feb68d7e&oe=617B9074",
        coverDark:
          "https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/42833393_2012094665533658_7578947685992540629_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=XyDroMo1YGAAX9_JS0f&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=14b91e4266be91ff72ca8b83feb68d7e&oe=617B9074"
      },
      {
        cover:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/102554303_700049214077748_7262706340822259390_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=lGGXxkSXE-cAX9P2uUU&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=29c9575a36e30f8f9fd43b3bc6dcb11b&oe=617B96B1",
        coverDark:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/102554303_700049214077748_7262706340822259390_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=lGGXxkSXE-cAX9P2uUU&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=29c9575a36e30f8f9fd43b3bc6dcb11b&oe=617B96B1"
      },
      {
        cover:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/36565462_193801854624136_4249942153108652032_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=eG9kRbHcOc4AX-HX0Hy&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=20a4c0c337c60cde87faceeebf247ef5&oe=617C666B",
        coverDark:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/36565462_193801854624136_4249942153108652032_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=eG9kRbHcOc4AX-HX0Hy&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=20a4c0c337c60cde87faceeebf247ef5&oe=617C666B"
      },
      {
        cover:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/37006125_202593743751654_3073166284175704064_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=8Z-CcVpYZNAAX8Dwxhc&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=ebbd1e5aa654bd15b0ca97298644ff65&oe=617B9177",
        coverDark:
          "https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/37006125_202593743751654_3073166284175704064_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=8Z-CcVpYZNAAX8Dwxhc&_nc_ht=scontent-lax3-1.cdninstagram.com&edm=AM6HXa8EAAAA&oh=ebbd1e5aa654bd15b0ca97298644ff65&oe=617B9177"
      }
    ]
  }
];

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const [authMode, setAuthMode] = useState("signin")
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        setDisplaySignIn(false);
      } 
    });
  }, [auth]);
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: "repeat-x",
        position: "relative"
      }}
    >
      <Box paddingY={{ xs: 0, sm: "4rem", md: "8rem" }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: "50%" }}>
            <Typography
              variant="h2"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 700
              }}
            >
              Sell your Instagram pics to{" "}
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`
                }}
              >
                <Typed
                  strings={["collectors", "ad agencies", "designers", "anyone"]}
                  typeSpeed={80}
                  loop={true}
                />
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              ArtCart makes it easy to sell your Instagram photos as stock
              images and collectable art
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "flex-start" }}
              marginTop={4}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                onClick={(event) => {
                  setAuthMode("signin");
                  setDisplaySignIn(true);
                }}
              >
                Log In
              </Button>
              <Box
                marginTop={{ xs: 2, sm: 0 }}
                marginLeft={{ sm: 2 }}
                width={{ xs: "100%", md: "auto" }}
              >
                <Button
                  onClick={(event) => {
                    setAuthMode("signup");
                    setDisplaySignIn(true);
                  }}
                  variant="text"
                  color="info"
                  size="large"
                  fullWidth={isMd ? false : true}
                >
                  Don't have an account? Sign up for free!
                </Button>

                <AuthModal
                  open={displaySignIn}
                  mode={authMode}
                  onClose={() => {
                    //if (user) {
                      setDisplaySignIn(false);
                    //}
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            transform: "rotate(-20deg)",
            display: { xs: "none", sm: "block" }
          }}
        >
          <Box
            display={"flex"}
            width={"50rem"}
            left={"50%"}
            top={0}
            position={"absolute"}
            sx={{ transform: "translate3d(20%, -50%, 0)" }}
          >
            {images.map((item, i) => (
              <Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                {item.group.map((g, j) => (
                  <Box
                    key={j}
                    padding={1}
                    bgcolor={"background.paper"}
                    borderRadius={2}
                    boxShadow={3}
                    marginTop={2}
                  >
                    <Box
                      component={LazyLoadImage}
                      effect="blur"
                      src={
                        theme.palette.mode === "dark" ? g.coverDark : g.cover
                      }
                      height={1}
                      width={1}
                      maxWidth={320}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: "100%",
          marginBottom: theme.spacing(-1)
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
