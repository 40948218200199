import React, { useEffect, useState } from "react";

import Main from "layouts/Main";
import Container from "components/Container";
import Loader from "components/Loader";
import { Main as MainSection } from "./components";
import { Box } from "@mui/material";
import { queryMediaForStore, refreshShopMedia } from "firebase";

const PortfolioGrid = props => {
  const storename = props.match.params.storename;

  //media should be sorted...probably better to do it server side....
  let [media, setMedia] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshShopMedia(storename).then(results => {
      queryMediaForStore(storename).then(results => {
        setMedia(results);
        setLoading(false);
      });
    });
  }, [storename]);

  return (
    <Main>
      <Container>
        {loading ? (
          <Box pt={5} pb={5} textAlign="center">
            
            <Loader loading={loading} isGrid={true}/>
            
          </Box>
        ) : (
          <MainSection media={media} storename={storename} />
        )}
      </Container>
    </Main>
  );
};

export default PortfolioGrid;
